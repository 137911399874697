
export const groupBy = (array, prop, modifier = null) => array.reduce((grp, item) => {
  let val = item[prop]
  if (typeof modifier === 'function') {
    val = modifier(val)
  }
  grp[val] = grp[val] || []
  grp[val].push(item)
  return grp
}, {})
